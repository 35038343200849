<template>
  <v-container>
    <h1>Devices Activity Report</h1>
    <v-row>
      <v-col cols="12" sm="6">
        <v-select
          v-model="selectedTimeFrame"
          :items="timeFrames"
          label="Select Time Frame"
          @change="updateReport"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="loading">
      <v-col class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <v-card>
          <v-card-title>Active Devices</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="activeDevicesHeaders"
              :items="activeDevices"
              :items-per-page="10"
            ></v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>Activity Distribution</v-card-title>
          <v-card-text>
            <canvas ref="activityChart"></canvas>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>Device Type Distribution</v-card-title>
          <v-card-text>
            <canvas ref="deviceTypeChart"></canvas>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Chart from "chart.js";
import apiClient from "@/services/ApiCall";
import moment from "moment";

export default {
  name: "DevicesSalesReport",
  data() {
    return {
      loading: true,
      selectedTimeFrame: "Last 30 Days",
      timeFrames: ["Last 30 Days", "Last 60 Days", "Last 90 Days"],
      activeDevices: [],
      activeDevicesHeaders: [
        { text: "Device Type", value: "deviceType" },
        { text: "Total Devices", value: "totalDevices" },
        { text: "Active (Last 30 Days)", value: "active30Days" },
        { text: "Active (Last 60 Days)", value: "active60Days" },
        { text: "Active (Last 90 Days)", value: "active90Days" },
      ],
      activityChart: null,
      deviceTypeChart: null,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        const response = await apiClient.getAPI();
        this.processData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.loading = false;
      }
    },
    processData(data) {
      const now = moment();
      const deviceStats = {};

      data.forEach((device) => {
        if (!deviceStats[device.device]) {
          deviceStats[device.device] = {
            totalDevices: 0,
            active30Days: 0,
            active60Days: 0,
            active90Days: 0,
          };
        }

        deviceStats[device.device].totalDevices++;

        // Convert lastCheckIn from milliseconds to a moment object
        const lastCheckIn = moment(device.lastCheckIn);
        const daysSinceCheckIn = now.diff(lastCheckIn, "days");

        if (daysSinceCheckIn <= 30) {
          deviceStats[device.device].active30Days++;
        }
        if (daysSinceCheckIn <= 60) {
          deviceStats[device.device].active60Days++;
        }
        if (daysSinceCheckIn <= 90) {
          deviceStats[device.device].active90Days++;
        }
      });

      this.activeDevices = Object.entries(deviceStats).map(([deviceType, stats]) => ({
        deviceType,
        ...stats,
      }));

      this.updateCharts();
    },
    updateCharts() {
      this.updateActivityChart();
      this.updateDeviceTypeChart();
    },
    updateActivityChart() {
      const ctx = this.$refs.activityChart.getContext("2d");
      const labels = ["30 Days", "60 Days", "90 Days"];
      const datasets = this.activeDevices.map((device) => ({
        label: device.deviceType,
        data: [device.active30Days, device.active60Days, device.active90Days],
        backgroundColor: this.getRandomColor(),
      }));

      this.activityChart = new Chart(ctx, {
        type: "bar",
        data: { labels, datasets },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: "Number of Active Devices",
              },
            },
          },
          plugins: {
            title: {
              display: true,
              text: "Device Activity Over Time",
            },
          },
        },
      });
    },
    updateDeviceTypeChart() {
      const ctx = this.$refs.deviceTypeChart.getContext("2d");
      const labels = this.activeDevices.map((device) => device.deviceType);
      const data = this.activeDevices.map((device) => device.totalDevices);

      this.deviceTypeChart = new Chart(ctx, {
        type: "pie",
        data: {
          labels,
          datasets: [
            {
              data,
              backgroundColor: labels.map(() => this.getRandomColor()),
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "right",
            },
            title: {
              display: true,
              text: "Distribution of Device Types",
            },
          },
        },
      });
    },
    updateReport() {
      this.fetchData();
    },
    getRandomColor() {
      return `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(
        Math.random() * 255
      )}, ${Math.floor(Math.random() * 255)}, 0.7)`;
    },
  },
};
</script>