<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <v-toolbar-title>SafeTelecom Analytics</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text to="/">
        Home
      </v-btn>
      <v-btn text to="/enhanced-sales">
        Enhanced Sales
      </v-btn>
      <v-btn text to="/devices-report">
        Devices Report
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
}
</script>