<template>
  <v-container>
    <h1>Enhanced Sales Visualization</h1>
    <v-row>
      <v-col cols="12" sm="6">
        <v-card>
          <v-card-title>Select Years</v-card-title>
          <v-card-text>
            <v-checkbox
              v-for="year in yearsList"
              :key="year"
              v-model="selectedYears"
              :label="year.toString()"
              :value="year"
              @change="updateCharts"
            ></v-checkbox>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-select
          :items="chartTypes"
          label="Chart Type"
          outlined
          v-model="selectedChartType"
          @change="updateCharts"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="loading">
      <v-col class="text-center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
        <p class="mt-2">Loading sales data...</p>
      </v-col>
    </v-row>
    <v-row v-else-if="error">
      <v-col>
        <v-alert type="error">{{ error }}</v-alert>
      </v-col>
    </v-row>
    <v-row v-else-if="noDataAvailable">
      <v-col>
        <v-alert type="info">No sales data available for the selected years.</v-alert>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <canvas ref="chartCanvas"></canvas>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Chart from 'chart.js';
import moment from 'moment';
import apiClient from '@/services/ApiCall';

export default {
  name: 'EnhancedSalesVisualization',
  data() {
    return {
      salesData: [],
      chart: null,
      selectedYears: [],
      yearsList: [],
      loading: false,
      error: null,
      noDataAvailable: false,
      selectedChartType: 'bar',
      chartTypes: [
        { text: 'Bar Chart', value: 'bar' },
        { text: 'Line Chart', value: 'line' },
        { text: 'Stacked Bar Chart', value: 'stacked-bar' },
        { text: 'Pie Chart (Total Sales)', value: 'pie' },
        { text: 'Radar Chart', value: 'radar' },
      ],
    };
  },
  async created() {
    await this.fetchData();
    this.initYearsList();
    this.initChart();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.error = null;
      try {
        const response = await apiClient.getAPI();
        this.salesData = response.data.map(item => ({
          date: moment.unix(item.lastUpdated / 1000),
          device: item.device || 'unknown'
        }));
        if (this.salesData.length === 0) {
          this.noDataAvailable = true;
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        this.error = 'Failed to load sales data. Please try again later.';
      } finally {
        this.loading = false;
      }
    },
    initYearsList() {
      const currentYear = new Date().getFullYear();
      const oldestDataYear = this.salesData.length > 0 
        ? Math.min(...this.salesData.map(sale => sale.date.year())) 
        : currentYear;
      this.yearsList = Array.from(
        {length: currentYear - oldestDataYear + 1}, 
        (_, i) => currentYear - i
      );
      this.selectedYears = [currentYear]; // Default to current year
    },
    initChart() {
      if (this.$refs.chartCanvas) {
        this.createChart();
      }
    },
    createChart() {
      const ctx = this.$refs.chartCanvas.getContext('2d');
      this.chart = new Chart(ctx, this.getChartConfig());
    },
    updateCharts() {
      if (this.chart) {
        this.chart.destroy();
      }
      this.createChart();
    },
    getChartConfig() {
      const data = this.processData();
      switch (this.selectedChartType) {
        case 'line':
          return this.getLineChartConfig(data);
        case 'stacked-bar':
          return this.getStackedBarChartConfig(data);
        case 'pie':
          return this.getPieChartConfig(data);
        case 'radar':
          return this.getRadarChartConfig(data);
        default:
          return this.getBarChartConfig(data);
      }
    },
    processData() {
      const salesByYear = {};
      const vendorSales = {};

      this.selectedYears.forEach(year => {
        salesByYear[year] = new Array(12).fill(0);
      });

      this.salesData.forEach(sale => {
        const year = sale.date.year();
        if (this.selectedYears.includes(year)) {
          salesByYear[year][sale.date.month()]++;
          if (!vendorSales[year]) vendorSales[year] = {};
          vendorSales[year][sale.device] = (vendorSales[year][sale.device] || 0) + 1;
        }
      });

      return { salesByYear, vendorSales };
    },
    getBarChartConfig(data) {
      const datasets = this.selectedYears.map(year => ({
        label: `${year} Sales`,
        data: data.salesByYear[year],
        backgroundColor: this.getRandomColor(),
      }));

      return {
        type: 'bar',
        data: {
          labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          datasets: datasets,
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              title: { display: true, text: 'Sales' },
            },
          },
        },
      };
    },
    getLineChartConfig(data) {
      const config = this.getBarChartConfig(data);
      config.type = 'line';
      config.data.datasets.forEach(dataset => {
        dataset.fill = false;
        dataset.tension = 0.1;
      });
      return config;
    },
    getStackedBarChartConfig(data) {
      const config = this.getBarChartConfig(data);
      config.options.scales.y.stacked = true;
      config.options.scales.x = { stacked: true };
      return config;
    },
    getPieChartConfig(data) {
      // For pie chart, we'll show the total sales for each year
      const yearlyTotals = this.selectedYears.map(year => ({
        year,
        total: data.salesByYear[year].reduce((sum, sales) => sum + sales, 0)
      }));

      return {
        type: 'pie',
        data: {
          labels: yearlyTotals.map(item => `${item.year} (${item.total} sales)`),
          datasets: [{
            data: yearlyTotals.map(item => item.total),
            backgroundColor: this.selectedYears.map(() => this.getRandomColor()),
          }],
        },
        options: {
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: 'Total Sales by Year',
            },
          },
        },
      };
    },
    getRadarChartConfig(data) {
      const datasets = this.selectedYears.map(year => ({
        label: `${year} Sales`,
        data: data.salesByYear[year],
        backgroundColor: `${this.getRandomColor()}33`,
        borderColor: this.getRandomColor(),
        pointBackgroundColor: this.getRandomColor(),
      }));

      return {
        type: 'radar',
        data: {
          labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          datasets: datasets,
        },
        options: {
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: 'Sales Comparison (Radar)',
            },
          },
        },
      };
    },
    getRandomColor() {
      return `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)})`;
    },
  },
};
</script>
