import Vue from "vue";
import App from "./App.vue";
import Vuetify from "vuetify/lib/framework";
import VueRouter from "vue-router";
import Home from "./views/Home.vue";
import EnhancedSalesVisualization from "./components/EnhancedSalesVisualization.vue";
import DevicesSalesReport from "./components/DevicesSalesReport.vue";

Vue.use(Vuetify);
Vue.use(VueRouter);

const vuetify = new Vuetify({
  // You can add any Vuetify options here if needed in the future
});

const routes = [
  { path: "/", name: "Home", component: Home },
  {
    path: "/enhanced-sales",
    name: "EnhancedSales",
    component: EnhancedSalesVisualization,
  },
  {
    path: "/devices-report",
    name: "DevicesReport",
    component: DevicesSalesReport,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
